<template>

        <div class="leisure_button_group_sub">
            <router-link tag="button" class="bw2"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_MABULS_SUPERMAN_SADALI}"
                         :to="{path:'/leisure/mablssadali2m', query: {t: new Date().getTime()}}">슈퍼맨 사다리</router-link>

            <router-link tag="button" class="bw2"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_MABULS_JOKER}"
                         :to="{path:'/leisure/mablsjoker', query: {t: new Date().getTime()}}"> 조커&할리퀸</router-link>

        </div>


</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureCompMablsGameLinks",
        props: {
            comp: {
                type: Number,
                default() {
                    return -1
                }
            },
        },
        data(){
            return {
                leisureConst:leisureConst,
            }
        }
    }
</script>

<style scoped>

</style>