<template>
  <div>
    <div class="leisure_buttons_group">
      <router-link tag="button" class="bw3 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_EOS}"
                   :to="{path:'/leisure/eospowerball1m', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/common/minigame_icon_comp_ntry_powerball.png" alt=""
             style="width: 14px;height: 14px;"> <span>EOS 파워볼</span>
      </router-link>
      <router-link tag="button" class=" bw3 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_NTRY}"
                   :to="{path:'/leisure/powersadali', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/common/minigame_icon_comp_ntry.png" alt="" style="width: 14px;height: 14px;"><span>엔트리</span>
      </router-link>
      <router-link tag="button" class="btn_minigame_comp bw3"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_BET365}"
                   :to="{path:'/leisure/bet365_superleague', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/common/minigame_icon_comp_bet365.png" alt=""
             style="width: 14px;height: 14px;"><span>BET365</span>
      </router-link>

    </div>
    <div class="leisure_buttons_group">
      <router-link tag="button" class="btn_minigame_comp bw2"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_STAR_SADALI}"
                   :to="{path:'/leisure/ssd1', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/common/minigame_icon_comp_bscore.png" alt=""
             style="width: 14px;height: 14px;"><span>보스코어</span>
      </router-link>
      <router-link tag="button" class="bw2 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_CROWN}"
                   :to="{path:'/leisure/sutda', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/common/minigame_icon_comp_crown.png" alt=""
             style="width: 14px;height: 14px;"><span>크라운</span>
      </router-link>

    </div>


  </div>

</template>
<script>
import sportsConst from "../../common/sportsConst";
import leisureConst from "../../common/leisureConst";

export default {
  name: "LeisureGameCompLinks",
  data() {
    return {
      leisureConst: leisureConst,
    }
  },
  methods: {
    commingsoon() {
      alert('준비중입니다')
    },
  }
}
</script>

<style scoped>

</style>