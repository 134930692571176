import { render, staticRenderFns } from "./LeisureCompNewNamedGameLinks.vue?vue&type=template&id=2ed7a20e&scoped=true&"
import script from "./LeisureCompNewNamedGameLinks.vue?vue&type=script&lang=js&"
export * from "./LeisureCompNewNamedGameLinks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ed7a20e",
  null
  
)

export default component.exports