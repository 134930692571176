<template>

        <div class="leisure_button_group_sub">
            <router-link tag="button" class="bw2"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BGBG_POWERBALL}"
                         :to="{path:'/leisure/bgbgpowerball', query: {t: new Date().getTime()}}">보글보글 파워볼</router-link>

            <router-link tag="button" class="bw2"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BGBG_SADALI}"
                         :to="{path:'/leisure/bgbgsadali', query: {t: new Date().getTime()}}">보글보글 사다리</router-link>
        </div>


</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureCompBGBGGameLinks",
        props: {
            comp: {
                type: Number,
                default() {
                    return -1
                }
            },
        },
        data(){
            return {
                leisureConst:leisureConst,
            }
        }
    }
</script>

<style scoped>

</style>