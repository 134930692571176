<template>


    <div class="leisure_button_group_sub">
        <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_NAMED_NEWPOWERBALL_3S}"
                     :to="{path:'/leisure/npowerball3s', query: {t: new Date().getTime()}}">파워볼 3분</router-link>

        <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_NAMED_NEWPOWERBALL_5S}"
                     :to="{path:'/leisure/npowerball5s', query: {t: new Date().getTime()}}">파워볼 5분</router-link>

        <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_NAMED_NEWPOWER_SADALI_3S}"
                     :to="{path:'/leisure/npowersadali3s', query: {t: new Date().getTime()}}">사다리 3분</router-link>
        <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_NAMED_NEWPOWER_SADALI_5S}"
                     :to="{path:'/leisure/npowersadali5s', query: {t: new Date().getTime()}}">사다리 5분</router-link>
    </div>


</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureCompNewNamedGameLinks",
        props: {
            comp: {
                type: Number,
                default() {
                    return -1
                }
            },
        },
        data(){
            return {
                leisureConst:leisureConst,
            }
        }
    }
</script>

<style scoped>

</style>